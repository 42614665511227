<template>
  <!-- 账户安全 -->
  <div class="bodys">
    <div class="footer">
      <div class="text">基本信息</div>
      <!-- 收款信息 -->
      <div class="shoukuan">
        <div class="mingcheng">
          <span>收款账户名称:</span>
          <span>银行账号:</span>
          <span>收款银行:</span>
        </div>
        <div class="info">
          <span>{{ record.accountName }}</span>
          <span>{{ record.accountNumber }}</span>
          <span>{{ record.accountBank }}</span>
        </div>
        <div class="list">
          <div class="wenzi">1、显示用户线下交易时的收款信息;</div>
          <div class="wenzi">2、若收款账户变更,请在企业认证资料中进行修改;</div>
        </div>
      </div>
      <div class="text text1">安全服务</div>
      <div class="anquan">
        <span class="span1">安 全 保 证</span>
        <span class="span2">为 您 保 驾 护 航</span>
      </div>
      <div class="jiaoyimima" style="margin-top: 30px">
        <span class="zhanghu">账户交易密码</span>
        <div class="mima">
          <div>
            设置交易密码,主要用于订单结算,账户提现等敏感操作更加独立安全！
          </div>
          <div class="caozuo">
            <div class="type" @click="setting()">设置</div>
            <div class="type" @click="changepsd(true)">修改</div>
          </div>
        </div>
      </div>
      <div class="border"></div>
      <div class="jiaoyimima">
        <span class="zhanghu">账户管理密码</span>
        <div class="mima">
          <div>设置管理密码,主要用于变更企业账号！</div>
          <div class="caozuo">
            <div class="type" @click="setting1()">设置</div>
            <div class="type" @click="changepsd(false)">修改</div>
          </div>
        </div>
      </div>
      <div class="border"></div>
    </div>
    <!-- 设置密码 -->
    <div v-if="this.type == 1">
      <InputPasswordDialog1
        ref=""
        :isShow="passWordIsShow"
        :title="title"
        @close="closeDialog('passWordIsShow')"
        @getPassword="getPassword"
      ></InputPasswordDialog1>
      <InputPasswordDialog2
        :isShow="passWordIsShow1"
        :title="title1"
        @close="closeDialog('passWordIsShow1')"
        @password2="getPassword1"
      ></InputPasswordDialog2>
      <InputPasswordDialog3
        :isShow="newgetPassword"
        :title="newtitle"
        @close="closeDialog('newgetPassword')"
        @password3="newgetPasswords"
      ></InputPasswordDialog3>
    </div>
    <div v-else>
      <InputPasswordDialog1
        :isShow="passWordIsShow"
        :title="title2"
        @close="closeDialog('passWordIsShow')"
        @getPassword="getPassword"
      ></InputPasswordDialog1>
      <InputPasswordDialog2
        :isShow="passWordIsShow1"
        :title="title3"
        @close="closeDialog('passWordIsShow1')"
        @password2="getPassword1"
      ></InputPasswordDialog2>
      <InputPasswordDialog3
        :isShow="newgetPassword"
        :title="newtitle"
        @close="closeDialog('newgetPassword')"
        @password3="newgetPasswords"
      ></InputPasswordDialog3>
    </div>
    <!-- 设置密码 -->
    <change-psd :visibleshow.sync="changePsdvisible" :othertype="othertype" />
  </div>
</template>

<script>
import {
  getCompanyAuthDetail,
  setPassword,
  setPwdd as pesd,
} from "@/api/userCenters.js";
import { setPwd, updatePwd, ifSetManagePwd, financeWallet } from "@/api/home";
import * as types from "@/store/action-types";
import Vue from "vue";
import md5 from "js-md5";
import * as ESASignClient from "@/utils/signConfig/ESASignClient.js";
export default {
  data() {
    return {
      othertype: false,
      passWordIsShow: false,
      passWordIsShow1: false,
      changePsdvisible: false,
      newgetPassword: false,
      newtitle: "请输入原密码",
      title: "请设置交易密码",
      title1: "请再次输入交易密码",
      title2: "请设置管理密码",
      title3: "请再次输入管理密码",
      breadcrumbDto: [
        { path: "/userIndex", title: "个人中心" },
        { path: "/userIndex", title: "资金管理" },
        { path: "/finance", title: "账户安全" },
      ],
      record: {},
      pwd: "",
      pwd2: "",
      oldPassword: "",
      type: 1,
      boom: null,
      isg: false,
      isge: false,
    };
  },
  mounted() {
    this.queryissetpassworld();
  },
  components: {
    InputPasswordDialog1: () =>
      import("../components/InputPasswordDialog1.vue"),
    InputPasswordDialog2: () =>
      import("../components/InputPasswordDialog2.vue"),
    changePsd: () => import("./components/changpsd.vue"),
    InputPasswordDialog3: () =>
      import("../components/newinputPasswordDialog.vue"),
  },
  methods: {
    queryissetpassworld() {
      ifSetManagePwd().then((res) => {
        if (res.code == 0) {
          this.isg = true;
        } else {
          this.isg = false;
        }
      });
      financeWallet({
        enterpriseId: Vue.ls.get(types.enterpriseId),
      }).then((res) => {
        if (res.code == 0) {
          this.isge = res.data;
        }
      });
    },

    JsESACheckIsKeyk(status, msg, retpar, params) {
      let _this = this;
      console.log(status);
      if (status == 0) {
        this.changePsdvisible = true;
      } else {
        alert(msg);
        return false;
      }
    },
    changepsd(boom) {
      // if (
      //   !this.beforhandle([
      //     "personalCenter:accountSecurity:managePwd:set",
      //     "personalCenter:accountSecurity:tradePwd:set",
      //   ])
      // )
      //   return;
      if (boom) {
        if(!this.isge){
          return this.$message.error("您未设置交易密码");
        }
        if (!this.beforhandle("personalCenter:accountSecurity:tradePwd:update"))
          return;
      }else{
         if(!this.isg){
          return this.$message.error("您未设置管理密码");
        }
        if (!this.beforhandle("personalCenter:accountSecurity:managePwd:update"))
          return;
      }
      if(!this.isg){
        
      }
      this.othertype = boom;
      this.boom = !boom;
      if (boom) {
        this.type = 1;
        // this.title2 = "请输入交易密码";
      } else {
        // this.title2 = "请输入管理密码";
        this.type = 2;
      }
      console.log(this.type);

      let checkUkeyWhite = Vue.ls.get(types.checkUkeyWhite);
      if (checkUkeyWhite == 0) {
        ESASignClient.ESACheckIsKey(this.JsESACheckIsKeyk, null, false);
      } else {
        this.newgetPassword = true;
      }
    },
    JsESACheckIsKeyk(status, msg, retpar, params) {
      let _this = this;
      console.log(status);
      if (status == 0) {
        this.newgetPassword = true;
      } else {
        alert(msg);
        return false;
      }
    },
    setting(foo) {
      if (!this.beforhandle("personalCenter:accountSecurity:tradePwd:set"))
        return;
      if (this.isge){
        return this.$message.error("您已经设置过交易密码，请勿重复操作");
      }
      foo ? (this.title = "请输入交易密码") : (this.title = "请设置交易密码");
      this.boom = !foo;
      this.type = 1;
      let checkUkeyWhite = Vue.ls.get(types.checkUkeyWhite);
      if (checkUkeyWhite == 0) {
        ESASignClient.ESACheckIsKey(this.JsESACheckIsKeyk, null, false);
      } else {
        this.passWordIsShow = true;
      }
    },
    setting1(foo) {
      if (
        !this.beforhandle("personalCenter:accountSecurity:managePwd:set")
      )
        return;
      if (this.isg){
        return this.$message.error("您已经设置过账户管理密码，请勿重复操作");
      }
      foo ? (this.title2 = "请输入管理密码") : (this.title2 = "请设置管理密码");
      this.boom = !!foo;
      this.type = 2;
      // ESASignClient.ESACheckIsKey(this.JsESACheckIsKeyks, null, false);
      let checkUkeyWhite = Vue.ls.get(types.checkUkeyWhite);
      if (checkUkeyWhite == 0) {
        ESASignClient.ESACheckIsKey(this.JsESACheckIsKeyks, null, false);
      } else {
        this.passWordIsShow = true;
      }
    },
    JsESACheckIsKeyks(status, msg, retpar, params) {
      let _this = this;
      console.log(status);
      if (status == 0) {
        this.passWordIsShow = true;
      } else {
        alert(msg);
        return false;
      }
    },
    closeDialog(tag) {
      this[tag] = false;
    },
    getCompanyAuthDetail() {
      getCompanyAuthDetail().then((res) => {
        this.record = res.data;
      });
    },
    // 获取密码
    getPassword(num) {
      this.pwd = md5(num);
      this.passWordIsShow = false;
      this.passWordIsShow1 = true;
    },
    newgetPasswords(num) {
      this.oldPassword = md5(num);
      this.newgetPassword = false;
      this.passWordIsShow = true;
    },
    // 再次获取密码
    getPassword1(num) {
      this.pwd2 = md5(num);
      console.log(this.pwd, this.pwd2);
      if (num.length == 6) {
        // this.password.push(md5(num));
        // console.log(this.password);
        // 判断两次输入的值一样不
        if (this.pwd != this.pwd2) {
          this.password = [];
          this.passWordIsShow1 = false;
          this.$notify.error({
            title: "错误",
            message: "两次密码不一致,请重新输入",
            position: "bottom-right",
          });
        } else {
          this.passWordIsShow1 = false;

          if (this.type == 1) {
            let config = {
              pwd: this.pwd,
              rePwd: this.pwd2,
              password: this.oldPassword,
              confirmPassword: this.pwd2,
              // type: this.type,
              // operateType: 1,
            };
            if (this.boom) {
              setPwd(config).then((res) => {
                if (res.code == 0) {
                  this.password = [];
                  this.$notify.success({
                    title: "成功",
                    message: "设置密码成功",
                    position: "bottom-right",
                  });
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                  });
                }
              });
            } else {
              updatePwd(config).then((res) => {
                if (res.code == 0) {
                  this.password = [];
                  this.$notify.success({
                    title: "成功",
                    message: "修改密码成功",
                    position: "bottom-right",
                  });
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                  });
                }
              });
            }
          } else {
            let config = {
              // pwd: this.pwd,
              confirmPassword: this.pwd2,
              password: this.pwd,
              oldPassword: this.oldPassword,
              type: this.type,
              operateType: 1,
            };
            if (!this.boom) {
              pesd(config).then((res) => {
                if (res.code == 0) {
                  this.password = [];
                  this.$notify.success({
                    title: "成功",
                    message: "设置管理密码成功",
                    position: "bottom-right",
                  });
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                  });
                }
              });
            } else {
              config.operateType = 2;
              pesd(config).then((res) => {
                if (res.code == 0) {
                  this.password = [];
                  this.$notify.success({
                    title: "成功",
                    message: "修改管理密码成功",
                    position: "bottom-right",
                  });
                } else {
                  this.$message({
                    type: "error",
                    message: res.message,
                  });
                }
              });
            }
          }
        }
      }
    },
  },
  created() {
    // 获取企业详情
    this.getCompanyAuthDetail();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-breadcrumb__item {
  height: 39px;
  line-height: 39px;
  // padding-top: 10px;
}

.bodys {
  min-height: 800px;
  margin-bottom: 18px;
  margin-top: 20px;
}

.footer {
  height: 660px;
  background: #ffffff;
  border: 0.5px solid #e5e5e5;
  .text {
    height: 47px;
    background: #f3f3f3;
    border-bottom: 1px solid #e5e5e5;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    line-height: 47px;
    padding-left: 12px;
  }
  .text1 {
    border-top: 1px solid #e5e5e5;
  }
  .shoukuan {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 220px;
    .shoukuanxinxi {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #000000;
      line-height: 35px;
      margin-left: 31px;
      margin-top: 26px;
      margin-bottom: 18px;
    }
    .mingcheng {
      margin-left: 116px;
      margin-right: 12px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      width: 120px;
      height: 88px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 35px;
    }
    .list {
      display: flex;
      padding-left: 52px;
      flex-direction: column;
      // align-items: center;
      justify-content: center;
      width: 481px;
      height: 106px;
      margin-right: 36px;
      background: #fcf7f0;
      border: 1px dashed #ec6941;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
    }
    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      width: 432px;
      height: 87px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #000000;
      line-height: 35px;
      // border: 1px dashed #ec6941;
    }
  }
  .jiaoyimima {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 70px;
    // background-color: pink;
    margin-left: 40px;
    margin-right: 52px;

    .zhanghu {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #000000;
      line-height: 35px;
      margin-left: 22px;
      margin-right: 45px;
    }
    .mima {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 214px);
      .caozuo {
        display: flex;
        margin-right: 13px;
        .type {
          width: 63px;
          height: 24px;
          border: 1px solid #e5e5e5;
          border-radius: 2px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #0e7af4;
          line-height: 24px;
          text-align: center;
          margin-right: 13px;
        }
      }
    }
  }
  .border {
    border: 1px dashed #e5e5e5;
    margin-left: 40px;
    margin-right: 52px;
  }
  .anquan {
    padding-left: 38px;
    padding-top: 20px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    height: 113px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    background: url("../../../../src/assets/images/infobg.jpg") no-repeat;
    // background: linear-gradient(-45deg, #00a0ff, #0a65ff);

    .span1 {
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      margin-bottom: 11px;
    }
    .span2 {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
    }
  }
}

// .box-card {
//   width: 1200px;
//   margin: 0 auto;
//   .header {
//     font-size: 18px;
//     font-family: PingFang SC;
//     font-weight: bold;
//     color: #333333;
//     line-height: 47px;
//   }
//   .footers {
//     display: flex;
//     margin-top: 20px;
//     .text {
//       font-size: 30px;
//       font-weight: 700;
//       margin-right: 40px;
//       width: 20%;
//     }
//     .footer {
//       display: flex;
//       width: 100%;
//       justify-content: space-between;
//       .mima {
//         font-size: 20px;
//         line-height: 40px;
//       }
//     }
//   }
//   .info {
//     font-size: 20px;
//     margin-bottom: 30px;
//   }
//   .mingcheng {
//     display: flex;
//     justify-content: space-around;
//     margin-bottom: 30px;
//   }
//   .wenzi {
//     font-size: 20px;
//     margin-bottom: 10px;
//     margin-left: 100px;
//   }
//   .text {
//     font-size: 20px;
//     font-weight: 700;
//     margin-right: 50px;
//   }
// }
</style>
